import "../scss/style.scss";
import "./delighters.min.js";

("use strict");

const hmbgBtn = document.getElementById("hmbgBtn");
const hmbgMenu = document.getElementById("hmbgMenu");
const overLay = document.getElementById("overLay");
const philosophyHei = document.getElementById("philosophyBg");
resizeHei();

function resizeHei() {
  const descHei = document.getElementById("descHei").offsetHeight;
  console.log(descHei);
  philosophyHei.style.height = descHei + 180 + "px";
}

window.onresize = function () {
  resizeHei();
};

hmbgBtn.addEventListener("click", () => {
  hmbgBtn.classList.toggle("js_active");
  hmbgMenu.classList.toggle("js_close");
  hmbgMenu.classList.toggle("js_open");
  overLay.classList.toggle("js_overLay");
});

hmbgMenu.addEventListener("click", () => {
  hmbgBtn.classList.toggle("js_active");
  hmbgMenu.classList.toggle("js_close");
  hmbgMenu.classList.toggle("js_open");
  overLay.classList.toggle("js_overLay");
});
